.rce-container-clist {
  margin-right: -16px !important;
  margin-left: -15px !important;
}

.rce-citem {
  background: transparent;
  padding-right: 20px;
  &:hover {
    background-color: #242532;
  }
}

.rce-citem-body--top-title {
  font-weight: 700;
  color: #d2d3e0;
}

.rce-citem-avatar {
  margin-left: 18px;
}

.rce-mbox {
  box-shadow: none;
}

.rceItemSelected {
  background-color: #242532;
  &:hover {
    background-color: #242532;
  }
}

.rce-citem-body--bottom-title {
  color: #d2d3e0;
}

.rce-citem-body--top-time {
  color: #d2d3e0;
}

.rce-mbox {
  background: #242532;
  color: #fffeff;
  max-width: 45%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rce-mbox-right {
  background: #007f7c;
  color: #fffeff;
}

.rce-mbox-text right {
  color: #fffeff;
}

.rce-mbox-time {
  display: none;
}

.rce-citem-avatar img {
  background: #c3c3c3 !important;
}
.Chat_avatar__fCzi6 {
  background-color: #c3c3c3;
}
