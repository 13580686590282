.chatContainer {
  display: flex;
  height: 88vh;
  @media (min-height: 450px) and (max-height: 770px) {
    height: 80vh;
  }
  @media (min-height: 300px) and (max-height: 450px) {
    height: 77vh;
  }
  @media (min-height: 299px) and (max-height: 350px) {
    height: 73vh;
  }
  background-color: #20212e;
  color: white;
  margin: 30px;
  border-radius: 8px;

  .sidebar {
    width: 35%;
    min-width: 30%;
    padding: 16px;
    border-right: 1px solid #2a2a2a;
    .searchBar {
      width: 94%;
      height: 45px;
      padding: 10px;
      margin-top: 10px;
      margin-left: 3%;
      background: #191a23;
      border: 1px solid #2d2c3c;
      border-radius: 4px;
      color: white;
    }

    .noChatList {
      width: 100%;
      height: 79vh;
      .noChatListBox {
        margin-top: -20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
      }
    }
    .chatList {
      height: 79vh;
      @media (min-height: 770px) and (max-height: 900px) {
        height: 76vh;
      }
      @media (min-height: 550px) and (max-height: 770px) {
        height: 65vh;
      }
      @media (min-height: 450px) and (max-height: 550px) {
        height: 60vh;
      }
      @media (min-height: 300px) and (max-height: 450px) {
        height: 45vh;
      }
      @media (max-height: 299px) {
        height: 35vh;
      }

      overflow: auto;
      margin-top: 5px;
      cursor: default;
    }
  }

  .chatWindowLoading {
    width: 100%;
    padding: 16px;
    .chatLoading {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #20212e;

      .loadingDot {
        width: 1.2rem;
        height: 1.2rem;
        margin: 2rem 0.3rem;
        background: #007f7c;
        border-radius: 50%;
        animation: 0.7s bounce infinite alternate;

        &:nth-child(2) {
          animation-delay: 0.3s;
        }

        &:nth-child(3) {
          animation-delay: 0.6s;
        }
      }
    }
  }

  @keyframes bounce {
    to {
      opacity: 0.3;
      transform: translate3d(0, -1rem, 0);
    }
  }

  .noChatWindow {
    width: 100%;
    padding: 16px;

    .noChatBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }

  .chatWindow {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    overflow: scroll;

    .chatHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 10px 5px;
      flex-wrap: wrap;
      gap: 10px;

      .leftSection {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .chatTitle {
        font-size: 16px;
        font-weight: bold;
        color: #d2d3e0;
      }

      .headerAvatar {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .chatMessages {
      flex: 1;
      overflow-y: auto;
      margin-left: -18px;
      margin-right: -18px;
      .dateSeparator {
        display: flex;
        align-items: center;
        margin: 26px 0;
        color: #d2d3e0;
        font-size: 14px;

        .leftLine,
        .rightLine {
          flex: 1;
          border-top: 1px solid #2d2c3c;
        }

        .dateText {
          margin: 0 8px;
          white-space: nowrap;
        }
      }
      .messageBox {
        margin-bottom: 10px;
      }
    }

    .chatInput {
      display: flex;
      align-items: center;
      padding: 10px;
      height: 75px;
      background: #242532;
      border-radius: 8px;
      .messageInput {
        background: transparent;
        .sendButton {
          background: #007f7c;
          height: 38px;
          width: 38px;
          padding: 0px;
          // color: white;
          // padding: 8px 16px;
          border: none;
          display: flex;
          border-radius: 24px;
          justify-content: center;
          align-items: center;
          // cursor: pointer;
        }
      }
    }
  }
}
