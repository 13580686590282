.patientDetails {
  margin-bottom: 5rem;
  .patientDetailsHeading {
    color: #d2d3e0;
    margin-top: 2rem;
    margin-left: 3rem;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .patientProfileContainer {
    display: flex;
    justify-content: space-between;
    width: 96%;
    margin-left: 3rem;
    margin-right: 2.4rem;
    margin-top: 2rem;
    @media (max-width: 796px) {
      flex-direction: column;
    }

    .patientProfile {
      margin-top: 0.8rem;
      display: flex;
      position: relative;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 1044px) {
        min-height: 240px;
      }

      @media (max-width: 1044px) {
        flex-direction: column;
      }

      .patientProfileAndInfo {
        display: flex;
      }

      .patientProfileImg {
        margin-right: 1.5rem;

        .badge {
          background: #007f7c;
          border-radius: 4px;
          color: #e5e7eb;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          font-size: 12px;
          font-weight: 600;
          position: absolute;
          top: 20px;
          left: 13.2px;
          width: 25px;
          height: 25px;
          transform: translate(-50%, -50%);
        }
      }
      .nameTag {
        margin-right: 1.5rem;
        height: 70px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.8rem;
        background-color: #ababb2;
        .initialLetters {
          font-size: 3rem;
        }
      }
      .patientInfo {
        margin-top: 10px;
        padding-right: 20px;

        @media (min-width: 1044px) {
          border-right: #2c2d3c solid 1px;
        }
        max-height: fit-content;

        .patientName {
          color: #d2d3e0;
          text-transform: capitalize;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 700;
          line-height: 1.6rem;
          &:not(:first-child) {
            margin-top: 0.8rem;
          }
        }
        .patientInfoElement {
          margin-top: 10px;
        }
        .patientAgeGender {
          color: #d2d3e0;
          text-transform: capitalize;
          font-size: 1.3rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.6rem;
          margin-top: 1.3rem;
          &:not(:first-child) {
            margin-top: 0.8rem;
          }
        }
      }

      .metricContainer {
        margin-left: 20px;
        display: flex;
      }
      .metricWrapper {
        height: 48px;
        background: transparent;
        margin-top: 10px;

        @media (min-width: 1044px) {
          margin-left: 20px;
        }

        @media (max-width: 1044px) {
          margin-top: 22px;
        }

        .rowOne {
          display: flex;
          gap: 8px;
        }
        .rowTwo {
          display: flex;
          gap: 8px;
          margin-top: 8px;
        }

        @keyframes flashBackground {
          0% {
            background-color: #ff0000;
            color: #007f7c;
          }
          50% {
            background-color: #20212e;
            color: #007f7c;
          }
          100% {
            background-color: #ff0000;
            color: #e5e7eb;
          }
        }

        .flashingMetricCard {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 46px;
          height: 46px;
          background-color: transparent;
          border-radius: 8px;
          border: 1px solid #2c2d3c;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          animation: flashBackground 1.5s infinite;

          .metricTitle {
            color: #e5e7eb;
            font-weight: 700;
            font-size: 13px;
          }
          .metricValue {
            padding-top: 4px;
            font-weight: 700;
            font-size: 14px;
          }
        }

        .metricCard {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 46px;
          height: 46px;
          background-color: #20212e;
          border-radius: 8px;
          border: 1px solid #2c2d3c;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          color: #007f7c;

          .metricTitle {
            color: #e5e7eb;
            font-weight: 700;
            font-size: 13px;
          }
          .metricValue {
            padding-top: 4px;
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
    }
    .actionButtonsContainer {
      @media (max-width: 796px) {
        margin-top: 2rem;
      }
      @media (min-width: 1044px) {
        margin-left: 2rem;
      }
      // .actionsContainer {
      //   display: flex;
      //   gap: 1.2rem;
      //   margin-top: 0.5rem;
      // }
      // .dateIdentifier {
      //   // width: 100%;
      //   width: 17.7rem;
      //   height: 3.2rem;
      //   margin-top: 1.6rem;
      //   border-radius: 4px;
      //   border: 1px solid #2c2d3c;
      //   background: #20212e;
      //   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      //   align-items: center;
      //   justify-content: center;
      //   display: flex;
      // }
      .creditWrapper {
        margin-top: 0.6rem;
        margin-right: 2rem;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 22px;

        @media (max-width: 1290px) {
          display: block;
        }
        // available credits previous style
        // .creditContainer {
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   height: 38px;
        //   border-radius: 6px;
        //   border: 1px solid #2c2d3c;
        //   background: #20212e;
        //   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        //   min-width: 166px;
        //   max-width: 170px;
        //   @media (max-width: 1290px) {
        //     margin-top: 1rem;
        //   }
        //   .creditIdentifier {
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;
        //     gap: 2rem;
        //     font-weight: 700;
        //     margin-left: -1rem;
        //     margin-right: -1rem;

        //     // .addCreditBtn {
        //     //   width: 4.9rem;
        //     //   height: 2.6rem;
        //     //   font-weight: 700;
        //     //   display: flex;
        //     //   justify-content: center;
        //     //   align-items: center;
        //     // }
        //   }
        // }
      }
    }
  }
  .patientDetailsContent {
    overflow-y: scroll;
    // height: 90vh;
    height: calc(100vh - 15.6rem);
    padding-bottom: 2rem;
    width: 99%;

    &::-webkit-scrollbar {
      width: 0rem;
      height: 0rem;
    }

    .pastHealthConditionTitle {
      margin-left: 3rem;
      margin-top: 3rem;
      margin-bottom: 0.8rem;
      color: #d2d3e0;

      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.2rem;
    }
    .healthConditionsContainer {
      width: 96.7%;
      margin-left: 3rem;
      margin-right: 2rem;
      // display: grid;
      // grid-template-columns: auto auto 1fr;
      // grid-column-gap: 1.3rem;
      // grid-row-gap: 0.8rem;
    }
    // .feedbackHeading {
    //   margin-left: 3rem;
    //   margin-top: 3rem;
    //   margin-bottom: 0.8rem;
    //   color: #d2d3e0;
    //
    //   font-size: 1.3rem;
    //   font-style: normal;
    //   font-weight: 400;
    //   line-height: 2.2rem;
    // }
    // .feedbackField {
    //   margin: 3rem 3rem;
    //   width: 80vw;
    //   height: 22rem;
    //   border-radius: 4px;
    //   border: 1px solid #2c2d3c;
    //   background: #20212e;
    //   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    // }

    .toggleAppointments {
      height: 4.4rem;
      display: flex;
      padding-left: 3rem;
      align-items: center;
      margin-top: 20px;
    }
    .radioBtnContainer {
      margin-top: 20px;
      margin-left: 3.2rem;
    }

    // appointment and notes container
    .listContainer {
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 0rem;
        height: 0rem;
      }
      width: 100%;
      flex: 1;
      padding-bottom: 50px;
      .cardList {
        padding-left: 30px;
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        gap: 0.8rem;
        width: 65.7rem;
      }
      .noAppointments {
        color: white;
        // position: absolute;
        // top: 60%;
        // left: 30%;
        // transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        background: #20212e;
        min-height: 48px;
        border-radius: 8px;
        width: 30rem;
      }
    }

    .feedbackTitle {
      color: #d2d3e0;
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1.5rem;
      padding-left: 3.1rem;
      padding-top: 3rem;
    }
    .feedbackArea {
      margin: 1rem 3rem;
      width: 96.6%;
      text-transform: none;
      color: #d2d3e0;
      @media (max-width: 992px) {
        width: 94%;
      }

      background: #20212e;
      border: none;
      min-height: 18rem;
      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.8rem;
      border: 0.05rem solid #2c2d3c;
      background: #20212e;
      box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);
    }

    .feedbackUploadContainer {
      margin: 3rem 3rem;
      width: 96.6%;
      @media (max-width: 992px) {
        width: 94%;
      }
      min-height: 22rem;
      max-height: 100%;
      display: flex;
      // justify-content: center;
      // align-items: center;
      border-radius: 0.8rem;
      border: 0.05rem solid #2c2d3c;
      background: #20212e;
      box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1);

      .feedbackContainer {
        display: flex;
        flex: 1;
        gap: 3rem;
        // justify-content: center;
        // flex-direction: column;
        // text-align: center;
        // padding-bottom: 0.5rem;
        .uploadBar {
          width: 32.1rem;
          height: 100%;
          border-radius: 0.8rem;
          // border: 1px dashed #2c2d3c;
        }
        .loadingContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 30px;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
          flex: 1;
          border-radius: 0.8rem;
          border: 1px solid #2d2c3c;
          background: #212231;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        }
        .uploadedFilesContainer {
          display: flex;
          // justify-content: center;
          padding-left: 30px;
          flex: 1;
          border-radius: 0.8rem;
          border: 1px solid #2d2c3c;
          background: #212231;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          .uploadedFiles {
            width: 241px;

            .uploadText {
              color: #d2d3e0;

              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px; /* 123.077% */
              margin-bottom: 0.8rem;
            }
            .fileListBar {
              display: flex;
              align-items: center;
              padding-left: 0.8rem;
              padding-right: 0.8rem;
              border-radius: 0.4rem;
              width: 241px;
              height: 3.2rem;
              border: 1px solid #2d2c3c;
              background: #212231;
              color: #d2d3e0;

              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 10px; /* 100% */
              margin-bottom: 0.4rem;
            }
          }
        }

        .cloudUploadSign {
          // display: flex;
          // justify-content: center;
          // flex-direction: column;
          // align-items: center;
          // margin-top: -2rem;
          // margin-left: 13.5rem;
          // margin-left: 6rem;
          // width: 2.4rem;
          // height: 2.4rem;
        }
        .dragText {
          color: #d2d3e0;

          font-size: 1.3rem;
          font-weight: 400;
          line-height: 1.5rem;
          // margin-left: 0.5rem;
          // margin-top: 1.5rem;
        }

        .textContent {
          margin-top: 1rem;
          margin-bottom: 1rem;
          color: #d2d3e0;

          font-size: 1.3rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
        }

        .button {
          margin-top: 3rem;
        }
      }
    }
  }
}

.questionnareParentContainer {
  .questionnareContainer {
    width: 100%;
    min-width: 22rem;
    // height: 6.2rem;
    border-radius: 8px;
    border: 1px solid #2c2d3c;
    background: #20212e;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    // @media (min-width: 769px) and (max-width: 992px) {
    //   min-width: 24rem;
    // }
    // @media (min-width: 992px) and (max-width: 1200px) {
    //   min-width: 40rem;
    // }
    @media (max-width: 575px) {
      min-width: 28rem;
    }
    .questionnareTitle {
      color: #d2d3e0;
      margin-top: 0.8rem;
      margin-left: 1.2rem;

      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.2rem;
    }
    .optionsContainer {
      width: 30rem;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 1rem;
      margin-left: 1.2rem;
      margin-bottom: 1.2rem;
      .optionItemsContainer {
        display: flex;
        gap: 0.4rem;
        .optionTick {
          margin-top: 0.3rem;
        }
        .optionItem {
          color: #d2d3e0;
          text-transform: capitalize;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.2rem;
        }
      }
    }
  }
}

// appointmentCardItem

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 5.8rem;
  background: #20212e;
  border: 1px solid #2c2d3c;
  border-radius: 4px;
  padding: 1rem 2rem;
  transition: background-color 0.2s ease;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  text-transform: capitalize;

  .wrapper {
    display: flex;
    align-items: center;

    .columnOne,
    .columnTwo,
    .columnThree {
      color: #fffeff;
      padding: 0 34px;
      position: relative;
      border-right: 1px solid #2c2d3c;
      min-height: 3.4rem;
      min-width: 150px;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        padding: 0 15px;
      }

      @media (max-width: 576px) {
        padding: 0 10px;
        min-width: 80px;
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
      }
    }

    .columnOne {
      font-weight: 500;
    }

    .columnTwo {
      font-weight: 500;
    }

    .columnThree {
      font-weight: 500;
    }
  }

  .actionColumn {
    flex-shrink: 0;
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
      margin-left: auto;
    }

    .cancelBtn {
      height: 2.8rem;
      width: 9.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fffeff;
      background: #c22c36;
      font-weight: 600;

      @media (max-width: 576px) {
        height: 2.5rem;
        width: auto;
        padding: 0 15px;
      }

      &:hover {
        background-color: #c22c36;
      }
    }

    .statusTag {
      height: 2.8rem;
      width: 9.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #007f7c;
      background: transparent;
      border: 1px solid #007f7c;
      font-weight: 600;
      text-transform: capitalize;
      cursor: default;

      @media (max-width: 576px) {
        height: 2.5rem;
        width: auto;
        padding: 0 15px;
      }
    }
  }
}

// notes Modal

.cardWrapper {
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: 15vh;
  border-radius: 4px;
  border: 1px solid #2c2d3c;
  background: #20212e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .textHeader {
    width: fit-content;
    position: fixed;
    color: #e5e7eb;
    font-size: 13px;
    font-style: normal;
    // font-weight: 700;
    line-height: normal;
    padding: 5px 9px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #2c2d3c;
    border-bottom: 1px solid #2c2d3c;
    background: #20212e;
  }

  .textContainer {
    height: 157px;
    width: fit-content;
    // resize: none;
    padding: 10px;
    color: #e5e7eb;
    margin-top: 2.5rem;
    background: #20212e;
  }
}

// .ant-radio-wrapper .ant-radio-inner::after {
//   background-color: blue !important;
// }

// Container Styling
.creditsComponent {
  width: 100%;
  min-width: 193px;
  max-width: 193px;
  .credits {
    display: flex;
    flex: 1;
    border-radius: 6px;
    height: 42px;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #2c2d3c;
    .type {
      margin-left: 10px;
      font-weight: 700;
    }
    .operatorWrapper {
      display: flex;
      flex: 1;
      gap: 8px;
      justify-content: center;
      align-items: center;

      .value {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26px;
        width: 32px;
        border-radius: 6px;
        border: 1px solid #2c2d3c;
      }
      .operator {
        height: 26px;
        width: 32px;
        font-size: 20px;
        background-color: #007f7c;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

// Custom Select

// .customSelect :global(.ant-select-selector) {
//   border-top: none !important;
//   border-left: none !important;
//   border-bottom: none !important;
//   border-right: 1px solid #2c2d3c !important;
//   border-top-right-radius: 0px;
//   border-bottom-right-radius: 0px;
//   background: transparent !important;
// }
