.selectStudio {
  min-width: 174px;
  margin-left: -10px;
  height: 32px;
  background-color: #20212e;
  border-radius: 4px;
}

.paginationArrows {
  background: #2c2d3c;
  border: none;
  border-radius: 50%; // Ensure circular shape
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendarContainer {
  width: 99%;
  height: 100%;
  // color: white;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-height: 950px) {
    overflow-y: scroll;
  }

  @media (min-height: 951px) and (max-height: 993px) {
    overflow-y: hidden;
  }

  .fixedHeaderSection {
    position: sticky;
    top: 0;
    z-index: 10;
    margin-bottom: 1px;
    display: flex;

    .emptyHeaderCell {
      width: 80px;
      position: relative;
      z-index: 0;
    }

    .headerRow {
      display: grid;
      //   margin-top: -0.8px;
      padding-top: 30px;
      grid-template-columns: repeat(7, 1fr);
      border-bottom: none;
      min-width: 620px;
      flex: 1;
      background: #191a23;
      font-weight: 700;

      .headerCell {
        padding-bottom: 8px;
        margin-top: -15px;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid #2c2d3c;
        background: #191a23;
      }
    }
  }

  .mainContentContainer {
    display: flex;
    flex: 1;
    overflow: visible;
    margin-bottom: 30px;

    .timeColumnContainer {
      width: 80px;
      .timeCell {
        height: 67px;
        font-weight: 600;
        position: relative;
        z-index: 1;

        .time {
          position: relative;
          top: -11px;
          left: 20px;
          z-index: 2;
        }
      }
    }

    .loadingContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75%;
      width: 89%;
    }

    .carouselContainer {
      flex: 1;
      overflow: hidden;

      .styledCarousel {
        :global {
          .slick-slide {
            overflow: hidden;
          }

          .slick-track {
            display: flex;
            align-items: flex-start;
          }
        }
      }

      .scrollableGridContainer {
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;

        .calendarGrid {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          min-width: 620px;

          .calendarCell {
            height: 67px;
            padding-top: 2.5px;
            padding-right: 4px;
            padding-left: 4px;
            padding-bottom: 4.5px;
            border-bottom: 1px solid #2c2d3c;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;
            gap: 2px;
            overflow: hidden;
            width: 100%;

            &:nth-last-child(-n + 7) {
              border-bottom: none;
            }

            .quarterRow {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 2px;
            }
          }
        }
      }
    }
  }
}
